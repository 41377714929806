import React from 'react';
import CardList from '../../style/card/CardList';
import { kuotaData } from '../../../data/kuota/telkomsel/MiniData';
import ExitButton from '../../style/exitbutton/ExitButton';

const MiniData: React.FC = () => {
    const freedomPackages = kuotaData.MiniData.Harian;

    return (
        <div className='container'>
            <h2>Tsl Mini Data</h2>
            <ExitButton text="Telkomsel" />
            <div className="card-list">
                {freedomPackages.map((pkg) => (
                    <CardList key={pkg.id} title={pkg.paket} price={pkg.harga} />
                ))}
            </div>
        </div>
    );
};

export default MiniData;
