// src/components/Kuota.tsx
import React from 'react';

const Discord: React.FC = () => {
  return (
    <div className='container'>
      <h2>No Data discord tab!</h2>
    </div>
  );
};

export default Discord;