import React from 'react';
import CardList from '../style/card/CardList';
import { Topup } from '../../data/TopUp/mlbb';
import ExitButton from '../style/exitbutton/ExitButton';

const on = true ;
const off = false ;

interface TopUp {
    id: number;
    topup: string;
    harga: string;
    finalPrice: string;
}

const harga = on;
const persentase = off;

const Mlbb: React.FC = () => {
    let TopupGame: TopUp[] = Topup.TopupMlbb.Mlbb.map((pkg) => {
        let hargaNumber = parseInt(pkg.harga.replace(/\./g, '')); // Konversi harga dari string ke number

        // Penambahan berdasarkan harga tetap
        if (harga) {
            if (pkg.id >= 1 && pkg.id <= 20) {
                hargaNumber += 3000; 
            } else if (pkg.id >= 21 && pkg.id <= 50) {
                hargaNumber += 5000; 
            } else if (pkg.id >= 51 && pkg.id <= 100) {
                hargaNumber += 10000; 
            } else if (pkg.id >= 101 && pkg.id <= 118) {
                hargaNumber += 15000;
            }
        }

        // Penambahan berdasarkan persentase sesuai ID
        if (persentase) {
            let percentageIncrease = 0; // Default persentase

            if (pkg.id >= 1 && pkg.id <= 20) {
                percentageIncrease = 0.10; // Naik 10%
            } else if (pkg.id >= 21 && pkg.id <= 50) {
                percentageIncrease = 0.15; // Naik 15%
            } else if (pkg.id >= 51 && pkg.id <= 100) {
                percentageIncrease = 0.20; // Naik 20%
            } else if (pkg.id >= 101 && pkg.id <= 118) {
                percentageIncrease = 0.25; // Naik 25%
            }

            hargaNumber += hargaNumber * percentageIncrease;
        }

        return {
            ...pkg,
            finalPrice: hargaNumber.toLocaleString('id-ID') // Konversi kembali ke format rupiah
        };
    });

    return (
        <div className='container'>
            <h2>Mobile Legend</h2>
            <ExitButton text="Top Up" />
            <div className="card-list">
                {TopupGame.map((pkg) => (
                    <CardList key={pkg.id} title={pkg.topup} price={pkg.finalPrice} />
                ))}
            </div>
        </div>
    );
};

export default Mlbb;
