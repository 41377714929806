export const Topup = {
    pubg: {
        uc: [
            { id: 1, topup: '25 UC', harga: '6.460' },
            { id: 2, topup: '15 UC', harga: '6.515' },
            { id: 3, topup: '50 UC', harga: '12.600' },
            { id: 4, topup: '60 UC', harga: '12.450' },
            { id: 5, topup: '35 UC', harga: '12.662' },
            { id: 6, topup: '70 UC', harga: '19.050' },
            { id: 7, topup: '100 UC', harga: '25.250' },
            { id: 8, topup: '150 UC', harga: '31.200' },
            { id: 9, topup: '125 UC', harga: '32.100' },
            { id: 10, topup: '200 UC', harga: '43.800' },
            { id: 11, topup: '210 UC', harga: '43.700' },
            { id: 12, topup: '250 UC', harga: '56.325' },
            { id: 13, topup: '300 UC', harga: '62.250' },
            { id: 14, topup: '350 UC', harga: '68.500' },
            { id: 15, topup: '375 UC', harga: '74.500' },
            { id: 16, topup: '500 UC', harga: '100.275' },
            { id: 17, topup: '525 UC', harga: '105.750' },
            { id: 18, topup: '700 UC', harga: '138.575' },
            { id: 19, topup: '750 UC', harga: '149.297' },
            { id: 20, topup: '1000 UC', harga: '200.375' },
            { id: 21, topup: '1100 UC', harga: '214.000' },
            { id: 22, topup: '1250 UC', harga: '241.600' },
            { id: 23, topup: '1500 UC', harga: '285.360' },
            { id: 24, topup: '1750 UC', harga: '310.500' },
            { id: 25, topup: 'Elite Pass Plus + Kupon Peti Klasik', harga: '359.365' },
            { id: 26, topup: '2500 UC', harga: '446.300' },
          ]        
    }
};