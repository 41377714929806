import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import './CardList.css'; // Pastikan file CSS diimpor

interface CardProps {
  title: string;
  price: string | number;
}

const CardList: React.FC<CardProps> = ({ title, price }) => {
  return (
    <Card className="custom-card">
      <CardContent>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body2" color="textSecondary">
          RP: {price}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CardList;
