// src/components/Kuota.tsx
import React from 'react';
import Card from '../../style/card/Card';
import ExitButton from '../../style/exitbutton/ExitButton';

const Kuota: React.FC = () => {
  return (
    <div className='container'>
      <h2>TELKOMSEL</h2>
      <ExitButton text="KUOTA" />
      <div className="card-container">
        <Card title="Data Bulanan" link="/tslbulanan" />
        {/* <Card title="Tsel Data Mini Harian" link="/tsl-mini-data" />
        <Card title="Tsel Data Mini Mingguan" link="/tsl-mini-data" />
        <Card title="Tsel Data Mini Malam" link="/tsl-mini-data" /> */}
      </div>
    </div>
  );
};

export default Kuota;
