// src/components/Home.tsx
import React from 'react';
import Card from './style/card/Card';

const Home: React.FC = () => {
  return (
    <div className='container'>
      <h2>DAFTAR HARGA</h2>
      <div className="card-container">
        <Card title="KUOTA" link="/Kuota" />
        <Card title="TOPUP" link="/topup" />
        {/*<Card title="E-WALLET" link="/wallet" /> */}
      </div>
    </div>
  );
};

export default Home;
