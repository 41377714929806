// src/components/Kuota.tsx
import React from 'react';
import Card from './style/card/Card';
import ExitButton from './style/exitbutton/ExitButton';

const Kuota: React.FC = () => {
  return (
    <div className='container'>
      <h2>KUOTA</h2>
      <ExitButton text="HOME" /> 
      <div className="card-container">
        <Card title="Indosat" link="/indosat-data" /> 
        <Card title="Telkomsel" link="/tsl-data" />  
      </div>
    </div>
  );
};

export default Kuota;
