// src/components/style/Card.tsx
import React from 'react';
import './Card.css';
import { Link } from 'react-router-dom';

interface CardProps {
  title: string;
  link: string;
}

const Card: React.FC<CardProps> = ({ title, link }) => {
  return (
  <Link to={link} className="card-link">
    <div className="card">
      <h3>{title}</h3>
      &gt;
    </div>
  </Link>
  );
};

export default Card;
