// src/components/style/exitbutton/ExitButton.tsx
import React from 'react';
import './ExitButton.css'; // Pastikan ini sesuai dengan nama file CSS yang benar

interface ExitButtonProps {
  text: string;
}

const ExitButton: React.FC<ExitButtonProps> = ({ text }) => {
  const handleClick = () => {
    window.history.back(); // Navigasi ke halaman sebelumnya
  };

  return (
    <div className="exit-button-container">
      <button onClick={handleClick} className="exit-button">
        {text}
      </button>
    </div>
  );
};

export default ExitButton;
