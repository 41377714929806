export const Topup = {
    TopupMlbb: {
        Mlbb: [
            { id: 1, topup: '5 Diamond', harga: '1.535' },
            { id: 2, topup: '10 Diamond', harga: '2.874' },
            { id: 3, topup: '12 Diamond', harga: '3.231' },
            { id: 4, topup: '14 Diamond', harga: '3.798' },
            { id: 5, topup: '18 Diamond', harga: '4.723' },
            { id: 6, topup: '19 Diamond', harga: '5.215' },
            { id: 7, topup: '28 Diamond', harga: '7.455' },
            { id: 8, topup: '36 Diamond', harga: '9.355' },
            { id: 9, topup: '44 Diamond', harga: '11.105' },
            { id: 10, topup: '56 Diamond', harga: '14.340' },
            { id: 11, topup: '59 Diamond', harga: '14.485' },
            { id: 12, topup: '74 Diamond', harga: '18.780' },
            { id: 13, topup: '85 Diamond', harga: '21.057' },
            { id: 14, topup: '86 Diamond', harga: '22.230' },
            { id: 15, topup: '1X Weekly Diamond Pass 220', harga: '25.325' },
            { id: 16, topup: '100 Diamond', harga: '25.590' },
            { id: 17, topup: '112 Diamond', harga: '28.490' },
            { id: 18, topup: '144 Diamond', harga: '35.683' },
            { id: 19, topup: '148 Diamond', harga: '37.184' },
            { id: 20, topup: '170 Diamond', harga: '42.015' },
            { id: 21, topup: '172 Diamond', harga: '42.980' },
            { id: 22, topup: '185 Diamond', harga: '46.335' },
            { id: 23, topup: '2X Weekly Diamond Pass 440', harga: '50.800' },
            { id: 24, topup: '222 Diamond', harga: '55.311' },
            { id: 25, topup: '229 Diamond', harga: '56.521' },
            { id: 26, topup: '240 Diamond', harga: '59.019' },
            { id: 27, topup: '257 Diamond', harga: '63.590' },
            { id: 28, topup: '278 Diamond', harga: '69.151' },
            { id: 29, topup: '284 Diamond', harga: '69.290' },
            { id: 30, topup: 'Mobile Legend Coupon Pass', harga: '69.180' },
            { id: 31, topup: '277 Diamond', harga: '69.645' },
            { id: 32, topup: '264 Diamond', harga: '71.500' },
            { id: 33, topup: '296 Diamond', harga: '71.600' },
            { id: 34, topup: '301 Diamond', harga: '73.980' },
            { id: 35, topup: '3X Weekly Diamond Pass 660', harga: '76.400' },
            { id: 36, topup: 'Starlight Membership', harga: '80.900' },
            { id: 37, topup: '345 Diamond', harga: '84.900' },
            { id: 38, topup: '344 Diamond', harga: '84.810' },
            { id: 39, topup: '355 Diamond', harga: '87.090' },
            { id: 40, topup: '374 Diamond', harga: '92.210' },
            { id: 41, topup: '370 Diamond', harga: '92.650' },
            { id: 42, topup: '381 Diamond', harga: '93.664' },
            { id: 43, topup: '408 Diamond', harga: '99.715' },
            { id: 44, topup: '4X Weekly Diamond Pass 880', harga: '101.200' },
            { id: 45, topup: '425 Diamond', harga: '104.809' },
            { id: 46, topup: '429 Diamond', harga: '106.090' },
            { id: 47, topup: '384 Diamond', harga: '107.200' },
            { id: 48, topup: '448 Diamond', harga: '125.200' },
            { id: 49, topup: '460 Diamond', harga: '125.200' },
            { id: 50, topup: '512 Diamond', harga: '126.016' },
            { id: 51, topup: '5X Weekly Diamond Pass 1100', harga: '126.200' },
            { id: 52, topup: '514 Diamond', harga: '127.030' },
            { id: 53, topup: '522 Diamond', harga: '128.752' },
            { id: 54, topup: 'Mobile Legend Twilight Pass', harga: '135.500' },
            { id: 55, topup: '568 Diamond', harga: '135.940' },
            { id: 56, topup: '500 Diamond', harga: '137.200' },
            { id: 57, topup: '518 Diamond', harga: '140.200' },
            { id: 58, topup: '601 Diamond', harga: '144.875' },
            { id: 59, topup: '600 Diamond', harga: '146.020' },
            { id: 60, topup: '642 Diamond', harga: '170.200' },
            { id: 61, topup: '712 Diamond', harga: '171.737' },
            { id: 62, topup: '706 Diamond', harga: '172.590' },
            { id: 63, topup: '717 Diamond', harga: '173.105' },
            { id: 64, topup: '750 Diamond', harga: '188.194' },
            { id: 65, topup: 'Starlight Member Plus', harga: '186.000' },
            { id: 66, topup: '875 Diamond', harga: '207.580' },
            { id: 67, topup: '965 Diamond', harga: '232.450' },
            { id: 68, topup: '977 Diamond', harga: '235.643' },
            { id: 69, topup: '963 Diamond', harga: '236.095' },
            { id: 70, topup: '1050 Diamond', harga: '254.180' },
            { id: 71, topup: '1134 Diamond', harga: '272.441' },
            { id: 72, topup: '1136 Diamond', harga: '273.508' },
            { id: 73, topup: '1139 Diamond', harga: '273.809' },
            { id: 74, topup: '1159 Diamond', harga: '278.370' },
            { id: 75, topup: '1164 Diamond', harga: '279.737' },
            { id: 76, topup: '1183 Diamond', harga: '284.187' },
            { id: 77, topup: '1220 Diamond', harga: '295.290' },
            { id: 78, topup: '1230 Diamond', harga: '295.500' },
            { id: 79, topup: '1368 Diamond', harga: '329.106' },
            { id: 80, topup: '1412 Diamond', harga: '344.525' },
            { id: 81, topup: '1443 Diamond', harga: '344.860' },
            { id: 82, topup: '1453 Diamond', harga: '350.345' },
            { id: 83, topup: '1507 Diamond', harga: '360.734' },
            { id: 84, topup: '1672 Diamond', harga: '401.081' },
            { id: 85, topup: '1704 Diamond', harga: '408.262' },
            { id: 86, topup: '1750 Diamond', harga: '417.211' },
            { id: 87, topup: '2010 Diamond', harga: '452.669' },
            { id: 88, topup: '2180 Diamond', harga: '495.084' },
            { id: 89, topup: '2199 Diamond', harga: '500.100' },
            { id: 90, topup: '2195 Diamond', harga: '520.290' },
            { id: 91, topup: '2350 Diamond', harga: '537.268' },
            { id: 92, topup: '2382 Diamond', harga: '544.824' },
            { id: 93, topup: '2380 Diamond', harga: '545.827' },
            { id: 94, topup: '2536 Diamond', harga: '582.490' },
            { id: 95, topup: '2578 Diamond', harga: '590.123' },
            { id: 96, topup: '2885 Diamond', harga: '661.474' },
            { id: 97, topup: '2904 Diamond', harga: '666.490' },
            { id: 98, topup: '3423 Diamond', harga: '795.222' },
            { id: 99, topup: '3453 Diamond', harga: '798.228' },
            { id: 100, topup: '3481 Diamond', harga: '805.482' },
            { id: 101, topup: '3693 Diamond', harga: '857.048' },
            { id: 102, topup: '4020 Diamond', harga: '906.738' },
            { id: 103, topup: '4404 Diamond', harga: '1.001.040' },
            { id: 104, topup: '4678 Diamond', harga: '1.064.836' },
            { id: 105, topup: '4830 Diamond', harga: '1.087.635' },
            { id: 106, topup: '4804 Diamond', harga: '1.103.078' },
            { id: 107, topup: '5398 Diamond', harga: '1.223.389' },
            { id: 108, topup: '5372 Diamond', harga: '1.238.833' },
            { id: 109, topup: '6030 Diamond', harga: '1.360.107' },
            { id: 110, topup: '5940 Diamond', harga: '1.364.664' },
            { id: 111, topup: '6001 Diamond', harga: '1.368.128' },
            { id: 112, topup: '6257 Diamond', harga: '1.431.747' },
            { id: 113, topup: '6840 Diamond', harga: '1.540.004' },
            { id: 114, topup: '7195 Diamond', harga: '1.626.898' },
            { id: 115, topup: '7660 Diamond', harga: '1.737.771' },
            { id: 116, topup: '7723 Diamond', harga: '1.753.161' },
            { id: 117, topup: '8040 Diamond', harga: '1.811.475' },
            { id: 118, topup: '8303 Diamond', harga: '1.888.879' }
        ]
    }
};