export const kuotaData = {
    FreedomNet: {
        freedom: [
            { id: 1, paket: 'Freedom Net 1GB 30 Hari', harga: '7.400' },
            { id: 2, paket: 'Freedom Net 2GB 30 Hari', harga: '13.589' },
            { id: 3, paket: 'Freedom Net 3GB 30 Hari', harga: '19.950' },
            { id: 4, paket: 'Freedom Net 4GB 30 Hari', harga: '27.049' },
            { id: 5, paket: 'Freedom Net 5,5GB 30 Hari', harga: '31.150' },
            { id: 6, paket: 'Freedom Net 5GB 30 Hari', harga: '33.274' },
            { id: 7, paket: 'Freedom Net 6GB 30 Hari', harga: '34.100' },
            { id: 8, paket: 'Freedom Net 7GB 30 Hari', harga: '34.788' },
            { id: 9, paket: 'Freedom+ 6,5GB + Bonus s/d 6,5GB 30Hr', harga: '40.248' },
            { id: 10, paket: 'Freedom Net 9GB 30 Hari', harga: '40.800' },
            { id: 11, paket: 'Freedom Net 8GB 30 Hari', harga: '42.799' },
            { id: 12, paket: 'Freedom Net 12GB 30 Hari', harga: '47.550' },
            { id: 13, paket: 'Freedom Net 10GB 30 Hari', harga: '48.305' },
            { id: 14, paket: 'Freedom Net 16GB 30 Hari', harga: '61.799' },
            { id: 15, paket: 'Freedom Net 15GB 30 Hari', harga: '64.433' },
            { id: 16, paket: 'Freedom Net 25GB 30 Hari', harga: '65.899' },
            { id: 17, paket: 'Freedom Net 20GB 30 Hari', harga: '72.313' },
            { id: 18, paket: 'Freedom Net 18GB 30 Hari', harga: '73.013' },

            { id: 19, paket: 'Freedom Net 40GB 30 Hari', harga: '93.900' },
            { id: 20, paket: 'Freedom Sensasi 50GB 30 Hari', harga: '105.395' },

            { id: 21, paket: 'Freedom Net 42GB 30 Hari', harga: '106.699' },
            
            { id: 22, paket: 'Freedom Sensasi 100GB 30 Hari', harga: '106.721' },
            { id: 23, paket: 'Freedom Sensasi 70GB 30 Hari', harga: '106.921' }
        ]        
    }
};