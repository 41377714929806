// src/components/Kuota.tsx
import React from 'react';
import Card from '../../style/card/Card';
import ExitButton from '../../style/exitbutton/ExitButton';

const Kuota: React.FC = () => {
  return (
    <div className='container'>
      <h2>INDOSAT</h2>
      <ExitButton text="KUOTA" /> 
      <div className="card-container"> 
        <Card title="Freedom Mini (Harian)" link="/freedommini" />
        <Card title="Freedom Internet (30 Hari)" link="/freedominternet" /> 
        <Card title='Freedom Net (30 Hari)'link='/freedomnet'/>
       
      </div>
    </div>
  );
};

export default Kuota;
