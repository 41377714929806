import React from 'react';
import CardList from '../style/card/CardList';
import { Topup } from '../../data/TopUp/FreeFire';
import ExitButton from '../style/exitbutton/ExitButton';

const on = true ;
// const off = false ;

interface TopUp {
    id: number;
    topup: string;
    harga: string;
    finalPrice: string;
}

const harga = on;
const persentase = on;

const ff: React.FC = () => {
    let TopupGame: TopUp[] = Topup.TopUpFreeFire.FreeFire.map((pkg) => {
        let hargaNumber = parseInt(pkg.harga.replace(/\./g, '')); // Konversi harga dari string ke number

        // Penambahan berdasarkan harga tetap
        if (harga) {
            if (pkg.id >= 1 && pkg.id <= 9) {
                hargaNumber += 4000; 
            } else if (pkg.id >= 10 && pkg.id <= 20) {
                hargaNumber += 8100; 
            }
        }

        // Penambahan berdasarkan persentase sesuai ID
        if (persentase) {
            let percentageIncrease = 0; 

            if (pkg.id >= 1 && pkg.id <=9) {
                percentageIncrease = 0.04;
            } else if (pkg.id >= 10 && pkg.id <= 20) {
                percentageIncrease = 0.05; 
            } 

            hargaNumber = Math.round(hargaNumber + hargaNumber * percentageIncrease);
        }

        return {
            ...pkg,
            finalPrice: hargaNumber.toLocaleString('id-ID') // Konversi kembali ke format rupiah
        };
    });

    return (
        <div className='container'>
            <h2>FreeFire</h2>
            <ExitButton text="Top Up" />
            <div className="card-list">
                {TopupGame.map((pkg) => (
                    <CardList key={pkg.id} title={pkg.topup} price={pkg.finalPrice} />
                ))}
            </div>
        </div>
    );
};

export default ff;
