import React from 'react';
import CardList from '../../style/card/CardList';
import { kuotaData } from '../../../data/kuota/indosat/FreedomNet';
import ExitButton from '../../style/exitbutton/ExitButton';

const on = true;
// const off = false ;

interface Internet {
    id: number;
    paket: string;
    harga: string;
    finalPrice: string;
}

const harga = on;
const persentase = on;

const FreedomNet: React.FC = () => {
    let kuota: Internet[] = kuotaData.FreedomNet.freedom.map((pkg) => {
        let hargaNumber = parseInt(pkg.harga.replace(/\./g, ''));

        if (harga) {
            if (pkg.id >= 1 && pkg.id <= 18) {
                hargaNumber += 5000;
            } else if (pkg.id >= 19 && pkg.id <= 20) {
                hargaNumber += 10000;
            }else if (pkg.id >= 21 && pkg.id <= 21) {
                hargaNumber += 10000;
            }else if (pkg.id >= 22 && pkg.id <= 22) {
                hargaNumber += 12000;
            }else if (pkg.id >= 23 && pkg.id <= 23) {
                hargaNumber += 10000;
            }
        }


        if (persentase) {
            let percentageIncrease = 0;
            if (pkg.id >= 19 && pkg.id <= 23) {
                percentageIncrease = 0.10;
            }
            // else if (pkg.id >= 14 && pkg.id <= 29) {
            //     percentageIncrease = 0.10;
            // }

            hargaNumber = Math.round(hargaNumber + hargaNumber * percentageIncrease);
        }

        return {
            ...pkg,
            finalPrice: hargaNumber.toLocaleString('id-ID')
        };
    });

    return (
        <div className='container'>
            <h2>Freedom Net 30 Hari</h2>
            <ExitButton text="INDOSAT" />
            <div className="card-list">
                {kuota.map((pkg) => (
                    <CardList key={pkg.id} title={pkg.paket} price={pkg.finalPrice} />
                ))}
            </div>
        </div>
    );
};

export default FreedomNet;
