// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//HOME
import Home from './components/Home';
import Navbar from './components/Navbar';
import Kuota from './components/Kuota';

import AboutMe from './components/navtab/AboutMe';
import Contacts from './components/navtab/Contacts';
import Discord from './components/navtab/Discord';

//TOPUP
import Topup from './components/Topup';
import Mlbb from './components/topUp/mlbb';
import FreeFire from './components/topUp/FreeFire';
import Pubg from './components/topUp/pubg';

//KUOTA INDOSAT
import IndosatData from './components/indosat/kuota/IndosatData';
import FreedomInternet from './components/indosat/kuota/FreedomInternet';
import FreedomMini from './components/indosat/kuota/FreedoMini';
import FreedomNet from './components/indosat/kuota/FreedomNet'

//KUOTA TELKOMSEL
import TslData from './components/telkomsel/kuota/TslData';
import TslBulanan from './components/telkomsel/kuota/DataBulanan';
import TslMiniData from './components/telkomsel/kuota/MiniData';

const App: React.FC = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/kuota" element={<Kuota />} />
        <Route path="/About" element={<AboutMe />} />
        <Route path="/Contacts" element={<Contacts />} />
        <Route path="/Discord" element={<Discord />} />

        {/* TOP UP */}
        <Route path="/topup" element={<Topup />} />
        <Route path="/mlbb" element={<Mlbb />} />
        <Route path="/freefire" element={<FreeFire />} />
        <Route path="/pubg" element={<Pubg />} />

        {/* KUOTA INDOSAT */}
        <Route path="/indosat-data" element={<IndosatData />} />
        <Route path="/freedominternet" element={<FreedomInternet />} />
        <Route path="/freedommini" element={<FreedomMini />} />
        <Route path="/freedomnet" element={<FreedomNet />} />

        {/* KUOTA TELKOMSEL */}
        <Route path="/tsl-data" element={<TslData />} />
        <Route path="/tslbulanan" element={<TslBulanan />} />
        <Route path="/tsl-mini-data" element={<TslMiniData />} />

      </Routes>
    </Router>
  );
};

export default App;
