import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import './style/Navbar.css'; // Import file CSS

const Navbar: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='nav-fixed'>
      <AppBar
        className='navbar-appbar'
        position="fixed"
        sx={{
          width: '98%',          // Mengatur lebar 90% dari layar
          left: '50%',           // Menempatkan elemen di tengah
          transform: 'translateX(-50%)', // Agar elemen benar-benar berada di tengah
        }}
      >
        <Toolbar>
          <Typography className='copyright' variant="h6" sx={{ flexGrow: 1 }}>
            Gadget Nusantara
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleClick}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose} component={Link} to="/">Home</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/discord">Discord</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/contacts">Contacts</MenuItem>
            <MenuItem onClick={handleClose} component={Link} to="/about">About Me</MenuItem>
          </Menu>
          <div className="navbar-container navbar-hidden">
            <Link to="/" className="navbar-item">Home</Link>
            <Link to="/discord" className="navbar-item">Discord</Link>
            <Link to="/contacts" className="navbar-item">Contacts</Link>
            <Link to="/about" className="navbar-item">About Me</Link>
          </div>
        </Toolbar>

      </AppBar>
    </div>
  );
};

export default Navbar;
