export const Topup = {
    TopUpFreeFire: {
        FreeFire: [
            { id: 1, topup: '5 Diamond', harga: '781' },
            { id: 2, topup: '12 Diamond', harga: '1.879' },
            { id: 3, topup: '50 Diamond', harga: '6.097' },
            { id: 4, topup: '70 Diamond', harga: '8.240' },
            { id: 5, topup: '100 Diamond', harga: '11.994' },
            { id: 6, topup: 'Membership Level Up Pass', harga: '12.649' },
            { id: 7, topup: '140 Diamond', harga: '16.430' },
            { id: 8, topup: '210 Diamond', harga: '24.420' },
            { id: 9, topup: 'Membership Mingguan 50 Diamond', harga: '24.713' },
            { id: 10, topup: '300 Diamond', harga: '36.536' },
            { id: 11, topup: 'Booyah Pass (BP CARD)', harga: '38.455' },
            { id: 12, topup: '355 Diamond', harga: '40.500' },
            { id: 13, topup: '500 Diamond', harga: '57.411' },
            { id: 14, topup: 'Membership Bulanan 50 Diamond', harga: '73.591' },
            { id: 15, topup: '720 Diamond', harga: '80.800' },
            { id: 16, topup: '1000 Diamond', harga: '113.560' },
            { id: 17, topup: '1450 Diamond', harga: '162.562' },
            { id: 18, topup: '2180 Diamond', harga: '248.150' },
            { id: 19, topup: '3640 Diamond', harga: '409.847' },
            { id: 20, topup: '4000 Diamond', harga: '467.323' }
        ]
    }
};