export const kuotaData = {
    FreedomMini: {
        freedom: [
            { id: 1, paket: '1GB 2 Hari', harga: '10.000' },
            { id: 2, paket: '2GB 1 Hari', harga: '12.500' },
            { id: 3, paket: '3GB 3 Hari', harga: '13.000' },
            { id: 4, paket: '2,5GB 5 Hari', harga: '15.000' },
            { id: 5, paket: '2GB 5 Hari', harga: '15.000' },
            { id: 6, paket: '3,5GB 5 Hari', harga: '18.000' },
            { id: 7, paket: '5GB 5 Hari', harga: '20.000' },
            { id: 8, paket: '5GB 3 Hari', harga: '20.000' },
            { id: 9, paket: '7GB 5 Hari', harga: '25.000' },
            { id: 10, paket: '7GB 7 Hari', harga: '28.000' },
            { id: 11, paket: '15GB 3 Hari', harga: '35.000' },
            { id: 12, paket: '15GB 5 Hari', harga: '37.000' }
          ]
          
    }
};