export const kuotaData = {
    FreedomInternet: {
        freedom: [
            { id: 1, paket: '1GB 30 Hari', harga: '13.000' },
            { id: 2, paket: '2GB 30 Hari', harga: '18.000' },
            { id: 3, paket: '3GB 30 Hari', harga: '23.000' },
            { id: 4, paket: '5,5GB 30 Hari', harga: '35.000' },
            { id: 5, paket: '7GB 30 Hari', harga: '40.000' },
            { id: 6, paket: '9GB 30 Hari', harga: '50.000' },
            { id: 7, paket: '16GB 30 Hari', harga: '70.000' },
            { id: 8, paket: '50GB 30 Hari', harga: '120.000' },
            { id: 9, paket: '100GB 30 Hari', harga: '140.000' },
            { id: 10, paket: '150GB 30 Hari', harga: '145.875' },
          ]          
    }
};