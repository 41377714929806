export const kuotaData = {
    TslBulanan: {
        Bulanan: [
            { "id": 1, "paket": "500MB All Jaringan 15 Hari", "harga": "7.100" },
            { "id": 2, "paket": "1GB All Jaringan 30 Hari", "harga": "11.689" },
            { "id": 3, "paket": "2GB All Jaringan 30 Hari", "harga": "20.899" },
            { "id": 4, "paket": "3GB All Jaringan 30 Hari", "harga": "23.234" },
            { "id": 5, "paket": "1,5GB All Jaringan 30 Hari", "harga": "24.375" },
            { "id": 6, "paket": "5GB All Jaringan 30 Hari gift", "harga": "26.000" },
            { "id": 7, "paket": "4GB All Jaringan 30 Hari", "harga": "30.234" },
            { "id": 8, "paket": "8GB All Jaringan 30 Hari gift", "harga": "33.000" },
            { "id": 9, "paket": "5GB All Jaringan 30 Hari", "harga": "33.629" },
            { "id": 10, "paket": "8GB All Jaringan 30 Hari", "harga": "35.400" },
            { "id": 11, "paket": "6GB All Jaringan 30 Hari", "harga": "35.500" },
            { "id": 12, "paket": "7GB All Jaringan 30 Hari", "harga": "35.300" },
            { "id": 13, "paket": "12GB All Jaringan 30 Hari", "harga": "54.900" },
            { "id": 14, "paket": "9GB All Jaringan 30 Hari", "harga": "54.199" },
            { "id": 15, "paket": "10GB All Jaringan 30 Hari", "harga": "55.300" },
            { "id": 16, "paket": "11GB All Jaringan 30 Hari", "harga": "66.350" },
            { "id": 17, "paket": "14GB All Jaringan 30 Hari", "harga": "66.500" },
            { "id": 18, "paket": "15GB All Jaringan 30 Hari", "harga": "67.200" },
            { "id": 19, "paket": "16GB All Jaringan 30 Hari", "harga": "132.000" },
            { "id": 20, "paket": "18GB All Jaringan 30 Hari", "harga": "133.500" },
            { "id": 21, "paket": "25GB All Jaringan 30 Hari", "harga": "135.650" },
            { "id": 22, "paket": "28GB All Jaringan 30 Hari", "harga": "140.000" },
            { "id": 23, "paket": "30GB All Jaringan 30 Hari", "harga": "156.000" },
            { "id": 24, "paket": "45GB All Jaringan 30 Hari", "harga": "174.000" },
            { "id": 25, "paket": "49GB All Jaringan 30 Hari", "harga": "176.000" },
            { "id": 26, "paket": "50GB All Jaringan 30 Hari", "harga": "179.000" },
            { "id": 27, "paket": "70GB All Jaringan 30 Hari", "harga": "184.750" },
            { "id": 28, "paket": "75GB All Jaringan 30 Hari", "harga": "185.950" },
            { "id": 29, "paket": "100GB All Jaringan 30 Hari", "harga": "191.100" }
        ]


    }
};