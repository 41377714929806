import React from 'react';
import CardList from '../../style/card/CardList';
import { kuotaData } from '../../../data/kuota/telkomsel/TslBulanan';
import ExitButton from '../../style/exitbutton/ExitButton';

// const on = true ;
//const off = false ;

interface Internet {
    id: number;
    paket: string;
    harga: string;
    finalPrice: string;
}

const harga = true;
const persentase = false;

const TslBulanan: React.FC = () => {
    let kuota: Internet[] = kuotaData.TslBulanan.Bulanan.map((pkg) => {
        let hargaNumber = parseInt(pkg.harga.replace(/\./g, '')); // Konversi harga dari string ke number

        if (harga) {
            if (pkg.id >= 14 && pkg.id <= 29) {
                hargaNumber += 6500; 
            }
        }

        if (persentase) {
            let percentageIncrease = 0;
            if (pkg.id >= 1 && pkg.id <= 13) {
                percentageIncrease = 0.20;
            }
            // else if (pkg.id >= 14 && pkg.id <= 29) {
            //     percentageIncrease = 0.10;
            // }

            hargaNumber = Math.round(hargaNumber + hargaNumber * percentageIncrease);
        }

        return {
            ...pkg,
            finalPrice: hargaNumber.toLocaleString('id-ID') // Konversi kembali ke format rupiah
        };
    });

    return (
        <div className='container'>
            <h2>Tsl Bulanan</h2>
            <ExitButton text="TELKOMSEL" />
            <div className="card-list">
                {kuota.map((pkg) => (
                    <CardList key={pkg.id} title={pkg.paket} price={pkg.finalPrice} />
                ))}
            </div>
        </div>
    );
};

export default TslBulanan;
