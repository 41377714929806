// src/components/Kuota.tsx
import React from 'react';

const AboutMe: React.FC = () => {
  return (
    <div className='container'>
      <h2>No Data</h2>
    </div>
  );
};

export default AboutMe;