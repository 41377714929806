import React from 'react';
import CardList from '../../style/card/CardList';
import { kuotaData } from '../../../data/kuota/indosat/FreedomMini';
import ExitButton from '../../style/exitbutton/ExitButton';

const FreedomMini: React.FC = () => {
    const freedomPackages = kuotaData.FreedomMini.freedom;

    return (
        <div className='container'>
            <h2>Freedom Mini</h2>
            <ExitButton text="Inodsat" />
            <div className="card-list">
                {freedomPackages.map((pkg) => (
                    <CardList key={pkg.id} title={pkg.paket} price={pkg.harga} />
                ))}
            </div>
        </div>
    );
};

export default FreedomMini;
