// src/components/TopUp.tsx
import React from 'react';
import Card from './style/card/Card';
import ExitButton from './style/exitbutton/ExitButton';

const TopUp: React.FC = () => {
  return (
    <div className='container'>
      <h2>TOP UP GAME</h2>
      <ExitButton text="HOME" /> 
      <div className="card-container">
        <Card title="Mobile Legendes" link="/mlbb" /> 
        <Card title="Pubg" link="/pubg" />
        <Card title="Free Fire" link="/freefire" />
        {/* <Card title="Genshin Impact" link="/" /> */}
      </div>
    </div>
  );
};

export default TopUp;
